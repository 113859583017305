<template>
	<div class="container-fluid">
		<PasswordResetForm></PasswordResetForm>
	</div>
</template>

<script>
	import PasswordResetForm from "../components/login/PasswordResetForm";

	export default {
		name: "reset-password",
		components: {PasswordResetForm}
	}
</script>

<style scoped>

</style>